import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { initializeAnalytics, logPageView } from './analytics';
import './App.css';
import ParentPage from './pages/ParentPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import Header from './components/Header';
import { HeaderProvider } from './contexts/HeaderContext';
import lfApi from './api/lfApi';

async function getUser() {
    try {
        const response = await lfApi.get('/auth/user');
        if (response.status === 200) {
            return response.data;
        }
        else {
            console.error('Failed to fetch user profile');
        }
    }
    catch (error) {
        console.error('Error fetching user profile:', error);
    }

    return null;
}

async function getUserProfile() {
    try {
        const response = await lfApi.get('/user/profile');
        if (response.status === 200) {
            return response.data;
        }
        else {
            console.error('Failed to fetch user profile');
        }
    }
    catch (error) {
        console.error('Error fetching user profile:', error);
    }

    return null;
}

const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        logPageView(location.pathname);
    }, [location]);
};

function App() {
    const [isUS, setIsUS] = useState(null);
    const [isUserLoading, setIsUserLoading] = useState(true);
    const [userProfile, setUserProfile] = useState(null);
    const [subscriptionAction, setSubscriptionAction] = useState({action: 'none'});

    const navigate = useNavigate();

    const fetchUser = async () => {
        const user = await getUser();
        if (user) {
            if (user.geo_data) {
                setIsUS(user.geo_data.country === 'US');
            }
            if (user.subscription_action) {
                setSubscriptionAction(user.subscription_action);
            }
            if (user.user_profile_id) {
                const profile = await getUserProfile();
                setUserProfile(profile);
            }
        }

        setIsUserLoading(false);
    }

    const logout = () => {
        setUserProfile(null);
        setSubscriptionAction({action: 'none'});
    };

    const onUserProfile = () => {
        navigate('/setting');
    };

    const onChildProfile = (username) => {
        navigate(`/profile/${username}`);
    };

    const onAddChildProfile = () => {
        navigate('/add_profile');
    };

    useEffect(() => {
        initializeAnalytics();
        fetchUser();
    }, []); // The empty array means this effect runs once on mount

    usePageTracking();

    return (
        <div>
            <div className="App flex flex-col h-screen">
                <HeaderProvider>
                    <Header
                        userProfile={userProfile}
                        subscriptionAction={subscriptionAction}
                        onUserProfile={onUserProfile}
                        onChildProfile={onChildProfile}
                        onAddChildProfile={onAddChildProfile} />

                    <main className="flex-1 p-4 pt-8 w-full mx-auto max-w-screen-lg">
                        {
                            isUS !== false ? (
                                isUserLoading
                                    ? <div>Loading...</div>
                                    : <Routes>
                                        <Route path="/signup" element={
                                            <SignupPage />
                                        } />
                                        <Route path="/*" element={
                                            userProfile
                                                ? <ParentPage 
                                                    userProfile={userProfile} 
                                                    subscriptionAction={subscriptionAction} 
                                                    setSubscriptionAction={setSubscriptionAction}
                                                    logout={logout} />
                                                : <LoginPage fetchUser={fetchUser} />
                                        } />
                                    </Routes>
                            ) : (
                                <div className="bg-red-500 text-white p-4">
                                    Sorry, Learnforest AI is not yet available in your region.
                                </div>
                            )
                        }
                    </main>

                    <footer className="bg-branch text-white text-center p-4 mt-12">
                        <p>
                            &copy; 2024 Learnforest LLC
                            <span className="mx-3">|</span> 
                            <a href="https://learnforest.ai/privacy.html" target="_blank" rel="noreferrer">Privacy Policy</a>
                            <span className="mx-3">|</span> 
                            <a href="mailto:support@learnforest.ai">Support</a>
                        </p>
                    </footer>
                </HeaderProvider>
            </div>
        </div>
    );
}

export default App;
